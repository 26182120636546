import api from '../library/axios';

export default {
  get_messages({ id }) {
    return api({
      url: `/api/commerce/setting/${id}`,
    });
  },
  update_messages({ id, data }) {
    return api({
      method: 'patch',
      url: `/api/commerce/setting/${id}`,
      data,
    });
  },
};
